/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
 
/** Ionic CSS Variables **/
:root {
    /* Gradient styles */
    --text-white:#fff;
    --gradient-color-primary: #10a424;
    --gradient-color-secondary: #10a424;
   
    /** primary **/
    --ion-primary-gradient: linear-gradient(
      88.06deg,
      var(--gradient-color-primary) 30%,
      var(--gradient-color-secondary) 86%
    );
    --ion-color-primary: #10a424;
    --ion-color-primary-rgb: 1, 128, 69;
    --ion-color-primary-contrast: #fafafa;
    --ion-color-primary-contrast-rgb: 250, 250, 250;
    --ion-color-primary-shade: #01713d;
    --ion-color-primary-tint: #1a9d60;
   
    /** secondary **/
    --ion-color-secondary: #000000;
    --ion-color-secondary-rgb: 55, 68, 110;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #303c61;
    --ion-color-secondary-tint: #4b577d;
   
    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
   
    /** success **/
    --ion-color-success: #34a300;
    --ion-color-success-rgb: 52, 163, 0;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #2e8f00;
    --ion-color-success-tint: #48ac1a;
    --input-background: #EAEEF3;
   
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
   
    /** danger **/
    --ion-color-danger: #ff6262;
    --ion-color-danger-rgb: 255, 98, 98;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e05656;
    --ion-color-danger-tint: #ff7272;
    --ion-color-lost: #cf0a13;
   
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
   
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
   
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
   
    /** Sports List Navbar Colors **/
    --ion-nav-card-background: var(--ion-color-primary);
    --ion-nav-card-text: var(--ion-color-primary-contrast);
   
    /** Header Colors **/
    --ion-header-text-color: #828db9;
    --ion-header-text-color-rgb: 130, 141, 185;
    --ion-header-background: #000000;
   
    --ion-header-gradient: linear-gradient(181.95deg, #000000 -21.5%, #01143D 109.44%);
   
    /** Back and Lay **/
    --back-odd-background: #97d3ff;
    --back-odd-background-light: #adcde5;
    --back-odd-background-rgb: 151, 211, 255;
    --lay-odd-background: #ffadad;
    --lay-odd-background-rgb: 255, 173, 173;
    --lay-odd-background-light: #e9b9c7;
   
    /** Table colour **/
    --table-headers-light: rgba(32, 32, 32, 0.6);
    --headers-font-family: 'Jost';
   
    /** Reports style **/
    --reports-bg-color: #ffffff;
    --page-titles-color: #fff;
    --reports-title-color: #1c1a53;
    --filter-controls-border: rgba(180, 180, 180, 1);
   
    /* Login logo */
    --login-title-color: #000;
    --login-xl-logo-width: 240px;
    --login-sm-logo-width: 156px;
   
    /* Dashboard */
    --summary-card-text-color: #000;
    --ion-name-cell-color: #041433;
   
    /** Header Logo Sizes **/
    --logo-xl-width: 176px;
    --logo-xl-height: 53px;
    --logo-lg-width: 176;
    --logo-lg-height: 53px;
    --logo-md-width: 96px;
    --logo-md-height: 24px;
    --logo-sm-width: 90px;
    --logo-sm-height: 53px;
    --logo-xs-width: 146px;
    --logo-xs-height: 53px;
  }
   
  /*
   * Light Colors
   * ------------------
   */
  .ios body,
  .md body {
    --ion-background-gradient: linear-gradient(
      88.06deg,
      var(--ion-background) 30%,
      var(--ion-background) 86%
    );
    --ion-background: #000000;
    --ion-background-color-rgb: 0, 20, 61;
    --ion-background-color: #fff;
   
    --ion-background-secondary: #021130;
    --ion-background-secondary-rgb: 49, 61, 84;
    --ion-background-tertiary: #313d54;
   
    --ion-text-color: #00143d;
    --ion-text-color-rgb: 0, 20, 61;
    --ion-text-light: #ffffff;
    --ion-text-light-rgb: 255, 255, 255;
    --ion-text-dark: #0a0d15;
    --ion-drawer-background:#072667;
    --ion-text-dark-rgb: 10, 13, 21;
    --ion-text-color-contrast: var(--ion-text-dark);
    --ion-text-color-contrast-rgb: var(--ion-text-dark-rgb);
   
    --ion-button-border: #00349D;
    /** Home page backgrounds **/
    --ion-home-grid-background: #e4e8ed;
   
    /** Card Variables**/
    --ion-card-background: #e4e8ed;
    --ion-card-text-color: #00143d;
    --ion-card-background-contrast: #ffffff;
    --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
    --ion-card-border-color-rgb: 10, 13, 21;
   
    /** Toolbar Variables**/
    --ion-toolbar-background: none;
    --ion-item-background: none;
    --ion-item-background-activated: none;
   
    /** Popover Variables**/
    --ion-popover-background: #001D58;
    --ion-popover--hover-background: #001D58;
   
    /** Expansion Panel Variables**/
    --accordion-summary-root-background: #ffffff;
    --accordion-summary-root-expanded-background: #202d57;
    --accordion-summary-root-text-color: var(--ion-text-color);
    --accordion-details-root-background: #e4e8ed;
   
    /** All Markets variables **/
    --am-accordion-summary-root-background: #e4e8ed;
    --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
    --am-accordion-summary-root-text-color: var(--ion-text-color);
    --am-accordion-details-root-background: #ffffff;
   
    /** IonSelect Styles **/
    --select-element-background: var(--ion-popover--hover-background);
    --select-popover-background: var(--ion-popover-background);
    --select-popover-active-background: var(--ion-popover--hover-background);
   
    /** Dialog Styles **/
    --dialog-header-background: #202d57;
    --dialog-content-background: #e4e8ed;
   
    /** DatePicker-Container Styles **/
    --date-picker-container-background: #ffffff;
    --date-picker-header-button-background: #e4e8ed;
   
    /** Drawer Styles **/
    --drawer-background: #e4e8ed;
    --drawer-background-contrast: #ffffff;
    --drawer-text-color: #00143d;
   
    /** Input Field Styles **/
    --input-background-color: #ffffff;
    --input-text-color: #0a0d15;
   
    /**casino card **/
    --casino-icon-background-col : #252872;
    --linear-color-change:#10a424;
   
    /* notification background color */
    --notification-background-col:#10a424;
    
    /** Reports style **/
    --reports-bg-color: #ffffff;
    --reports-title-color: var(--ion-color-primary);
    --filter-controls-border: rgba(180, 180, 180, 1);
   
    /* Dashboard */
    --summary-card-text-color: #000;
    --ion-name-cell-color: #131c31;
  }